@import "styles/variables.scss";

// .mobile-navigation {
.second-nav {
  display: none;
  z-index: 50;
  position: relative;

  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: $white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }

  &-menu,
  .menu {
    background-color: $violet-100;
    padding: 24px;
    margin: 24px;
    border-radius: 8px;
    position: fixed;
    right: 0;
    top: 150vh;
    left: 0;
    // transition: all 0.6s ease-in-out;

    &-visible {
      top: 96px;
    }

    &url,
    .links {
      display: flex;
      gap: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-element {
        color: $white;
        text-decoration: none;
      }
    }

    &__actions,
    .actions {
      display: flex;
      gap: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-element {
        color: $white;
        text-decoration: none;
      }

      &__button {
        width: 100%;
      }
    }

    &__separator {
      display: block;
      height: 1px;
      width: 100%;
      background-color: $grey-100;
      margin: 32px 0;
    }
  }

  @media (max-width: 800px) {
    display: block;
  }
}
