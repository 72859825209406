@import "styles/variables.scss";

.card {
  position: relative;
  background-color: $white;
  padding: 80px 32px 40px 32px;
  margin-top: 44px;
  border-radius: 8px;

  &-img {
    position: absolute;
    top: -25px;
    left: 40%;
  }
  @media (max-width: 750px) {
  }

  &-title {
    margin-bottom: 16px;
  }

  &-p {
    color: $grey-100 !important;
  }
  &-button {
    margin: 10px 0px !important;
    cursor: copy;
  }
}
