@import "styles/variables.scss";

.cards {
  // background-color: $blue-40;
  background-color: #f5f6f7;

  &__heading {
    text-align: center;
    color: $black;
    margin-bottom: 16px;
  }

  &-titles {
    padding-top: 40px;
  }

  &-p {
    text-align: center;
    color: $grey-100;
    max-width: 560px;
    margin: 0 auto 60px auto;
  }

  &-container {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 32px;
    padding-bottom: 80px;

    &::after {
      // content: "";
      // position: absolute;
      // top: calc(50% + 0px);
      // right: 40px;
      // left: 40px;
      // width: calc(100% - (2 * 40px));
      // height: 8px;
      // border-radius: 4px;
      // background: linear-gradient($green-60, $green-80);
    }
  }

  &-element {
    // background-color: $white;
    // z-index: 5;

    // &:nth-child(1) {
    //   margin-bottom: 80px;
    // }

    // &:nth-child(2) {
    //   margin-top: 80px;
    //   margin-bottom: 40px;
    //   // position: relative;
    //   // top: 40px;
    // }

    // &:nth-child(3) {
    //   margin-top: 120px;
    //   // position: relative;
    //   // top: 80px;
    // }
    background-color: $white;
    z-index: 5;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 750px) {
    &-container {
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 32px;
      padding-top: 80px;

      &::after {
        // content: "";
        // position: absolute;
        // top: calc(0% + 120px);
        // right: unset;
        // bottom: calc(0%);
        // left: 50%;
        // height: calc(100% - (2 * 120px));
        // width: 8px;
        // border-radius: 4px;
        // background: linear-gradient($green-60, $green-80);
      }
    }

    &-element {
      background-color: $white;
      z-index: 5;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
        margin-bottom: 40px;
      }
    }
  }
}
