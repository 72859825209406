@import "styles/variables.scss";

.main-nav {
  //   &-content {
  &-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  //   &url,
  &-url,
//   .links {
  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    // &-element {
    &_element {
      &:hover {
        color: $black !important;
      }
    }
  }

  &__actions,
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-left: auto;

    &-element {
      align-self: center;

      &:hover {
        color: $black !important;
      }
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
}
