@import "styles/variables";

.hero {
  &-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 64px;
  }

  &__img {
    width: 100%;
    margin-bottom: 30px;
    border-radius: 10%;
  }

  &-img {
    margin-bottom: 32px !important;
    display: block;
    width: 100%;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__heading {
    margin-top: 120px;
    margin-bottom: 16px;
  }

  &-p {
    margin-bottom: 32px;
    color: $grey-100;
  }

  &__button {
    margin-bottom: 120px;
  }

  @media (max-width: 1050px) {
    &-content {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      gap: 16px;
    }

    &-img {
      max-width: 600px;
    }

    &-content {
      justify-content: center;
      align-items: center;
    }

    &-title {
      margin-top: 0;
      text-align: center;
    }

    &__text {
      text-align: center;
    }
  }

  @media (max-width: 800px) {
    &-img {
      margin-top: 80px;
    }
    &__button {
      margin-bottom: 12px;
    }
  }
}
