@import "styles/variables.scss";

.footer {
  background-color: $black;

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &__logo {
    color: $white;
  }

  &url,
  .links {
    display: flex;
    flex-direction: row;
    gap: 32px;

    &__container {
      display: flex;
      flex-direction: column;
    }
    &__heading {
      color: $white;
      margin-bottom: 16px;
    }

    &-element {
      &:hover {
        background: linear-gradient($green-60, $green-80);
        background-clip: text;
        box-decoration-break: clone;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;
      }
    }
  }

  @media (max-width: 950px) {
    &-content {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 64px;
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &url,
    .links {
      display: flex;
      flex-direction: column;
      gap: 32px;

      &__heading,
      &-element {
        width: 100%;
        text-align: center;
      }
    }
  }
}
