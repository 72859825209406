* {
  font-family: "Roboto", sans-serif;
}

.button {
  font-size: 20px;
  font-weight: 600;

  &--small {
    font-size: 15px;
  }
}

.link {
  font-size: 15px;
  font-weight: 500;
}

.input {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.hero-p {
  font-size: 22px;
}

.second-nav .a-element,
.second-nav .actions-element {
  font-size: 18px;
}

.main-nav .a-element,
.main-nav .actions-element {
  font-weight: 600;
}

.input__input {
  font-size: 18px;
}

.input__error-text {
  font-size: 15px;
}

.output__link {
  font-size: 20px;
}

.heading {
  &-l1 {
    font-size: 80px;
    line-height: 90px;
    font-weight: 600;
  }

  &-l2 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
  }

  &-l3 {
    font-size: 22px;
    line-height: 25px;
    font-weight: 600;
  }
}

@media (max-width: 750px) {
  .heading {
    &-l1 {
      font-size: 42px;
      line-height: 50px;
    }

    &-l2 {
      font-size: 32px;
      line-height: 40px;
    }

    &-l3 {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .hero-p {
    font-size: 18px;
  }
}
