@import "styles/variables.scss";

.button {
  display: inline-block;
  padding: 16px 40px;
  background: $green-80;
  color: $white;
  text-decoration: none;
  border-radius: 32px;
  text-align: center;

  &--round {
    border-radius: 32px;
  }

  &--squarish {
    border-radius: 8px;
  }

  &--small {
    padding: 8px 20px;
  }

  &--clicked {
    background: $violet-100 !important;
  }

  &:hover {
    background: darken($green-80, 5%);
  }
}
