@import "styles/variables.scss";

.input {
  width: 100%;

  &-element {
    padding: 16px 32px;
    border: 0;
    border-radius: 8px;
    outline: 0;
    background-color: $white;
    width: 100%;
    color: $black;
    height: 62px;

    &::placeholder {
      color: $black;
      opacity: 0.5;
    }
  }

  &-message {
    display: none;
    padding: 8px 0;
    color: $red-100;
  }

  &--error {
    & .input-element {
      padding-left: 32px;
      border: 3px solid $red-100;

      &::placeholder {
        color: $red-100 !important;
      }
    }

    & .input__error-text {
      display: block;
    }
  }
}
