.social-media-icons {

	&__link {
		margin-right: 24px;
		display: inline-block;

		&:last-child {
			margin-right: 0 !important;
		}

	}
	
}