$turquoise: #2bd0d0;
$black: #34313d;
$white: #ffffff;
$blue-40: #d9e5f6;
$grey-100: #9e9aa8;
$grey-80: #bfbfbf;
$grey-60: #eff1f7;
// $violet-100: #3a3054;
$violet-100: #794e8c;
$violet-80: #4b3f6b;
// $green-80: #179E7C;
$green-80: #ef635b;
$green-60: #32c78d;
$red-100: #f46363;
